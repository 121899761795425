import { observable, computed, action, makeObservable, toJS } from 'mobx'
import DefaultInvoiceReport from '../../reports/Invoices/DefaultInvoiceReport'
import DefaultProjectReport from '../../reports/Projects/DefaultProjectReport'
import DefaultResourceScheduleReport from '../../reports/ResourceSchedule/DefaultResourceScheduleReport'
import DefaultRevenueForecastReport from '../../reports/RevenueForecast/DefaultRevenueForecastReport'
import DefaultStaffReport from '../../reports/Staff/DefaultStaffReport'
import DefaultTimeReport from '../../reports/Time/DefaultTimeReport'
import DefaultExpenseReport from '../../reports/Expense/DefaultExpenseReport'
import OrganisationCollection from '../Collections/OrganisationCollection'
import ReportCollection from '../Collections/ReportCollection'
import ResourceScheduleReportCollection from '../Collections/ResourceScheduleReportCollection'
import RevenueForecastReportCollection from '../Collections/RevenueForecastReportCollection'
import InvoiceTemplateModel from './InvoiceTemplateModel'
import Model from './Model'
import ReportModel from './ReportModel'
import ResourceScheduleReportModel from './ResourceScheduleReportModel'
import RevenueForecastReportModel from './RevenueForecastReportModel'
import StaffCollection from '../Collections/StaffCollection'
import cuid from 'cuid'

class OrganisationModel extends Model {
    @observable accountingSystem = null
    @observable settings = {}
    @observable defaultRevenueForecastReportId = null
    @observable defaultResourceScheduleReportId = null
    @observable defaultProjectReportId = null
    @observable defaultStaffReportId = null
    @observable defaultTimeReportId = null
    @observable defaultInvoiceReportId = null
    @observable defaultContactReportId = null
    @observable defaultExpenseReportId = null
    @observable creatorStaffMemberId = null
    @observable migratedAt = null
    @observable country = null
    @observable invoiceTemplates = {}

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = OrganisationCollection
        this.init(data, options)
    }

    @computed
    get defaultInvoiceTemplate() {
        const id =
            Object.keys(this.invoiceTemplates || {})[0] ||
            Object.keys(this.settings?.invoice?.templates || {})[0] ||
            cuid()
        this.invoiceTemplates ??= {}
        this.invoiceTemplates[id] ??= new InvoiceTemplateModel(
            this,
            this.settings?.invoice?.templates?.[id] || {}
        )
        return this.invoiceTemplates?.[id]
    }

    @computed
    get defaultProjectReport() {
        return (
            ReportCollection.reportsById[this.defaultProjectReportId] ||
            new ReportModel(
                { ...DefaultProjectReport, systemReport: true },
                { detached: true }
            )
        )
    }

    @computed
    get defaultStaffReport() {
        return (
            ReportCollection.reportsById[this.defaultStaffReportId] ||
            new ReportModel(
                { ...DefaultStaffReport, systemReport: true },
                { detached: true }
            )
        )
    }

    @computed
    get defaultInvoiceReport() {
        return (
            ReportCollection.reportsById[this.defaultInvoiceReportId] ||
            new ReportModel(
                { ...DefaultInvoiceReport, systemReport: true },
                { detached: true }
            )
        )
    }

    @computed
    get defaultTimeReport() {
        return (
            ReportCollection.reportsById[this.defaultTimeReportId] ||
            new ReportModel(
                { ...DefaultTimeReport, systemReport: true },
                { detached: true }
            )
        )
    }

    @computed
    get defaultExpenseReport() {
        return (
            ReportCollection.reportsById[this.defaultExpenseReportId] ||
            new ReportModel(
                { ...DefaultExpenseReport, systemReport: true },
                { detached: true }
            )
        )
    }

    @computed
    get defaultRevenueForecastReport() {
        return (
            RevenueForecastReportCollection.revenueForecastReportsById[
                this.defaultRevenueForecastReportId
            ] ||
            new RevenueForecastReportModel(
                {
                    ...DefaultRevenueForecastReport,
                    filters: {
                        ...DefaultRevenueForecastReport.filters,
                        invoiceData: (
                            this.settings.reportInvoiceDateType || 'issueDate'
                        ).replace('issueDate', 'issuedOn'),
                    },
                    systemReport: true,
                },
                {
                    detached: true,
                }
            )
        )
    }

    @computed
    get defaultResourceScheduleReport() {
        return (
            ResourceScheduleReportCollection.resourceSchedulesById[
                this.defaultResourceScheduleReportId
            ] ||
            new ResourceScheduleReportModel(
                { ...DefaultResourceScheduleReport, systemReport: true },
                {
                    detached: true,
                }
            )
        )
    }

    @computed
    get accountingSystemSettings() {
        this.settings.accountingSystemSettings ??= {}
        return this.settings?.accountingSystemSettings[this.accountingSystem]
    }

    @action.bound
    updateInvoiceTemplate(templateData) {
        const settings = { ...this.settings }
        settings.invoice ??= {}
        settings.invoice.templates ??= {}
        settings.invoice.templates[templateData.id] = templateData
        this.update({ settings })
    }

    @action.bound
    updateInvoiceSettings(invoiceSettings) {
        const settings = { ...this.settings }
        settings.invoice ??= {}
        settings.invoice = { ...settings.invoice, ...invoiceSettings }
        this.update({ settings })
    }

    @action.bound
    updateAccountingSystemSettings(accountingSystemSettings) {
        const settings = { ...this.settings }
        settings.accountingSystemSettings ??= {}
        settings.accountingSystemSettings[this.accountingSystem] ??= {}
        settings.accountingSystemSettings[this.accountingSystem] = {
            ...settings.accountingSystemSettings[this.accountingSystem],
            ...accountingSystemSettings,
        }
        this.update({ settings })
    }

    @computed
    get creatorStaff() {
        return StaffCollection.staffsById[this.creatorStaffMemberId]
    }
}

export default OrganisationModel
