import React, { useEffect, useState } from 'react'
import './_styles/timesheetapp.scss'
import Root from './Pages/Root'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import posthog from 'posthog-js'
import SessionStore from './State/SessionStore'
import useVisible from './visibleHook'
import { ClerkProvider } from '@clerk/clerk-react'
const isDev = process.env.REACT_APP_ENV === 'development'

// if (process.env.REACT_APP_ENV === 'production') {
posthog.init('phc_Burb304xExjYSqj20cLeUVWCO2DfMIZqELYlfzC89i6', {
    api_host: isDev ? 'https://app.posthog.com' : 'https://hog.coincraft.co',
    ui_host: 'https://app.posthog.com',
    session_recording: {
        maskAllInputs: false,
    },
})
// }

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            suspense: true,
            staleTime: 0, //5 * 60 * 1000, // 5 minutes
            refetchOnWindowFocus: false, // default: true
        },
    },
})

function App() {
    const visibilityState = useVisible()
    useEffect(() => {
        SessionStore.checkIfUpdateIsAvailable()
    }, [visibilityState])
    return (
        <ClerkProvider
            publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
            afterSignOutUrl="/"
        >
            <QueryClientProvider client={queryClient}>
                <Root />
            </QueryClientProvider>
        </ClerkProvider>
    )
}

export default App
