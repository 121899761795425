import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import StaffModel from '../Models/StaffModel'
import CostCentreCollection from './CostCentreCollection'
import StaffRateCollection from './StaffRateCollection'
import { permissionPresets } from '../Permissions/PermissionPresets'

class StaffCollection extends Collection {
    constructor() {
        super({ collection: 'staff', modelClass: StaffModel })
        this.addLookup('staffByRoleId', 'manyByKey', {
            key: (t) => t.roleId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('staffByCostCentreId', 'manyByKey', {
            key: (t) => t.costCentreId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('activeStaff', 'list', {
            filter: (t) => !t.isDeleted && !t.deletedAt && !t.isArchived,
        })
        makeObservable(this)
    }
    get staffsById() {
        return this.modelsById
    }
    get staffs() {
        return this.models
    }
    get staffById() {
        return this.modelsById
    }
    get staff() {
        return this.models
    }
    get staffMembersById() {
        return this.modelsById
    }
    get staffMembers() {
        return this.models
    }
    @action.bound
    addStaff(data) {
        this.add(data)
    }
    @action.bound
    addStaffs(data) {
        this.addMany(data)
    }
    @action.bound
    createStaff() {
        const staff = this.add(
            {
                costCentreId:
                    CostCentreCollection.activeCostCentres.find((cc) =>
                        cc.name.toLowerCase().includes('staff')
                    )?.id || CostCentreCollection.activeCostCentres[0].id,
                permissions: permissionPresets.timesheet,
            },
            { trackUpdates: true }
        )
        const rate = StaffRateCollection.add(
            {
                staffId: staff.id,
                date: new Date(2010, 0, 1),
            },
            { trackUpdates: true }
        )
        return staff
    }
}

export default new StaffCollection()
export const StaffCollectionClass = StaffCollection
