import { observable, computed, action, makeObservable } from 'mobx'
import OrganisationSubscriptionCollection from '../Collections/OrganisationSubscriptionCollection'
import Model from './Model'
import { differenceInMonths, endOfDay, startOfDay } from 'date-fns'
import _ from 'lodash'
import SessionStore from '../SessionStore'

class OrganisationSubscriptionModel extends Model {
    @observable subscribedAt = null
    @observable startedTrialOn = null
    @observable cardBrand = null
    @observable cardLast4 = null
    @observable cardExpDate = null
    @observable endsTrialOn = null
    @observable receiptEmail = null
    @observable stripeId = null
    @observable credit = null
    @observable nextPaymentDate = null
    @observable monthlyAdminPlanId = null
    @observable monthlyCouponId = null
    @observable monthlyStandardPlanId = null
    @observable subscriptionPeriod = null
    @observable unpaidInvoices = null
    @observable yearlyAdminPlanId = null
    @observable yearlyCouponId = null
    @observable yearlyStandardPlanId = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = OrganisationSubscriptionCollection
        this.init(data, options)
    }

    update(data, options) {
        super.update(data, options)
    }

    get trialEndDate() {
        return endOfDay(new Date(this.endsTrialOn))
    }

    get hasSubscription() {
        return this.subscribedAt !== null
    }

    get hasTrialExpired() {
        if (this.hasSubscription) {
            return false
        }
        return this.trialEndDate < endOfDay(new Date())
    }

    get hasCreditCardExpired() {
        if (!this.cardExpDate) {
            return false
        }
        return endOfDay(this.cardExpDate) < endOfDay(new Date())
    }

    get willCreditCardExpireInLessThanAMonth() {
        if (!this.cardExpDate) {
            return false
        }
        return differenceInMonths(this.cardExpDate, new Date()) < 1
    }

    get invoicesDue() {
        return this.unpaidInvoices.filter(
            (inv) =>
                (inv.stripe_invoice_id !== 'proration' || inv.amount >= 50) &&
                inv.date
        )
    }

    get hasUnpaidInvoices() {
        return this.invoicesDue.length > 0
    }

    get earliestUnpaidInvoiceDate() {
        if (!this.invoicesDue.length > 0) {
            return null
        } else {
            return _.min(this.invoicesDue.map((inv) => new Date(inv.date)))
        }
    }

    get hasOverdueInvoices() {
        return false
        if (!this.earliestUnpaidInvoiceDate) {
            return false
        }
        return this.earliestUnpaidInvoiceDate < endOfDay(new Date())
    }

    get isAccountLocked() {
        //TODO - add unpaid invoices
        return this.hasTrialExpired || this.hasOverdueInvoices
    }

    get defaultMonthlyStandardPlanId() {
        const subscribedDate = this.subscribedAt
            ? new Date(this.subscribedAt)
            : null
        if (subscribedDate && subscribedDate < new Date('2023-03-20')) {
            return ['au', 'nz'].includes(SessionStore?.organisationCountry)
                ? '1_Month_Aus_Standard'
                : '1_Month_US_Standard'
        }
        return ['au', 'nz'].includes(SessionStore?.organisationCountry)
            ? '1_Month_Aus_35'
            : '1_Month_US_25'
    }

    get defaultYearlyStandardPlanId() {
        const subscribedDate = this.subscribedAt
            ? new Date(this.subscribedAt)
            : null
        if (subscribedDate && subscribedDate < new Date('2023-03-20')) {
            return ['au', 'nz'].includes(SessionStore?.organisationCountry)
                ? '12_Month_Aus_Standard'
                : '12_Month_US_Standard'
        }
        return ['au', 'nz'].includes(SessionStore?.organisationCountry)
            ? '12_Month_Aus_35'
            : '12_Month_US_23'
    }

    get defaultMonthlyAdminPlanId() {
        const subscribedDate = this.subscribedAt
            ? new Date(this.subscribedAt)
            : null
        if (subscribedDate && subscribedDate < new Date('2023-03-20')) {
            return ['au', 'nz'].includes(SessionStore?.organisationCountry)
                ? '1_Month_Aus_Admin'
                : '1_Month_US_Admin'
        }
        return ['au', 'nz'].includes(SessionStore?.organisationCountry)
            ? '1_Month_Aus_35'
            : '1_Month_US_25'
    }

    get defaultYearlyAdminPlanId() {
        const subscribedDate = this.subscribedAt
            ? new Date(this.subscribedAt)
            : null
        if (subscribedDate && subscribedDate < new Date('2023-03-20')) {
            return ['au', 'nz'].includes(SessionStore?.organisationCountry)
                ? '12_Month_Aus_Admin'
                : '12_Month_US_Admin'
        }
        return ['au', 'nz'].includes(SessionStore?.organisationCountry)
            ? '12_Month_Aus_35'
            : '12_Month_US_25'
    }

    get monthlyAdminPlanPrice() {
        return planLookup[
            this.monthlyAdminPlanId || this.defaultMonthlyAdminPlanId
        ].amount
    }

    get monthlyStandardPlanPrice() {
        return planLookup[
            this.monthlyStandardPlanId || this.defaultMonthlyStandardPlanId
        ].amount
    }

    get yearlyAdminPlanPrice() {
        return planLookup[
            this.yearlyAdminPlanId || this.defaultYearlyAdminPlanId
        ].amount
    }

    get yearlyStandardPlanPrice() {
        return planLookup[
            this.yearlyStandardPlanId || this.defaultYearlyStandardPlanId
        ].amount
    }

    get yearlyDiscount() {
        return (
            couponLookup[this.yearlyCouponId || '15_percent_forever']
                ?.percentDiscount || 0
        )
    }

    get monthlyDiscount() {
        return couponLookup[this.monthlyCouponId]?.percentDiscount || 0
    }

    get taxPercent() {
        return taxLookup[SessionStore?.organisationCountry]?.taxPercent || 0
    }
}

export default OrganisationSubscriptionModel

const planLookup = {
    '1_Month_Aus_Admin': {
        id: '1_Month_Aus_Admin',
        interval: 'month',
        intervalCount: 1,
        amount: 4500,
        currency: 'aud',
    },
    '1_Month_Aus_Standard': {
        id: '1',
        interval: 'month',
        intervalCount: 1,
        amount: 2000,
        currency: 'aud',
    },
    '12_Month_Aus_Admin': {
        id: '12_Month_Aus_Admin',
        interval: 'year',
        intervalCount: 1,
        amount: 54000,
        currency: 'aud',
    },
    '12_Month_Aus_Standard': {
        id: '12_Month_Aus_Standard',
        interval: 'year',
        intervalCount: 1,
        amount: 24000,
        currency: 'aud',
    },
    '6_Month_Aus_Admin': {
        id: '6_Month_Aus_Admin',
        interval: 'month',
        intervalCount: 6,
        amount: 27000,
        currency: 'aud',
    },
    '6_Month_Aus_Standard': {
        id: '6_Month_Aus_Standard',
        interval: 'month',
        intervalCount: 6,
        amount: 12000,
        currency: 'aud',
    },
    '1_Month_Aus_Standard_$15': {
        id: '15AUD_User',
        interval: 'month',
        intervalCount: 1,
        amount: 1500,
        currency: 'aud',
    },
    '12_Month_Aus_Standard_$15': {
        id: '12_Month_15AUD',
        interval: 'year',
        intervalCount: 1,
        amount: 18000,
        currency: 'aud',
    },
    '12_Month_Aus_Standard_BS': {
        id: '12_Month_Timesheet_BS',
        interval: 'year',
        intervalCount: 1,
        amount: 18000,
        currency: 'aud',
    },
    '1_Month_US_Admin': {
        id: '1month_admin_usd',
        interval: 'month',
        intervalCount: 1,
        amount: 3500,
        currency: 'usd',
    },
    '1_Month_US_Standard': {
        id: '1month_timesheet_usd',
        interval: 'month',
        intervalCount: 1,
        amount: 1500,
        currency: 'usd',
    },
    '12_Month_US_Admin': {
        id: '12month_admin_usd',
        interval: 'year',
        intervalCount: 1,
        amount: 42000,
        currency: 'usd',
    },
    '12_Month_US_Standard': {
        id: '12month_timesheet_usd',
        interval: 'year',
        intervalCount: 1,
        amount: 18000,
        currency: 'usd',
    },
    '1_Month_US_Any_$23': {
        id: '1_monthly_plan',
        interval: 'month',
        intervalCount: 1,
        amount: 2300,
        currency: 'usd',
    },
    '12_Month_US_Admin_$30': {
        id: '12month_admin_30usd',
        interval: 'year',
        intervalCount: 1,
        amount: 36000,
        currency: 'usd',
    },
    '1_Month_Aus_29': {
        id: 'price_1MnYUZC5Z2UupQFYupQNBHo6',
        interval: 'month',
        intervalCount: 1,
        amount: 2900,
        currency: 'aud',
    },
    '12_Month_Aus_29': {
        id: 'price_1MnYV3C5Z2UupQFYEBt85US1',
        interval: 'year',
        intervalCount: 1,
        amount: 34800,
        currency: 'aud',
    },
    '1_Month_US_19': {
        id: 'price_1MnYW7C5Z2UupQFYHpzWaEih',
        interval: 'month',
        intervalCount: 1,
        amount: 1900,
        currency: 'usd',
    },
    '12_Month_US_19': {
        id: 'price_1MnYWeC5Z2UupQFYmgDbRJqy',
        interval: 'year',
        intervalCount: 1,
        amount: 22800,
        currency: 'usd',
    },
    '1_Month_Aus_35': {
        id: 'price_1Q8B6xC5Z2UupQFYHil12VMM',
        interval: 'month',
        intervalCount: 1,
        amount: 3500,
        currency: 'aud',
    },
    '12_Month_Aus_35': {
        id: 'price_1Q8B7tC5Z2UupQFY5PjrScn4',
        interval: 'year',
        intervalCount: 1,
        amount: 42000,
        currency: 'aud',
    },
    '1_Month_US_25': {
        id: 'price_1Q8B8tC5Z2UupQFYLobMM9KB',
        interval: 'month',
        intervalCount: 1,
        amount: 2500,
        currency: 'usd',
    },
    '12_Month_US_25': {
        id: 'price_1Q8B9RC5Z2UupQFYCVOeNrd7',
        interval: 'year',
        intervalCount: 1,
        amount: 30000,
        currency: 'usd',
    },
}

const couponLookup = {
    '10_percent_forever': { id: '10_percent_forever', percentDiscount: 10 },
    '15_percent_forever': { id: '15_percent_forever', percentDiscount: 15 },
    '20_percent_forever': { id: '20_percent_forever', percentDiscount: 20 },
    '25_percent_forever': { id: '25_percent_forever', percentDiscount: 25 },
    '35_percent_forever': { id: '35_percent_forever', percentDiscount: 35 },
    '50_percent_forever': { id: '50_percent_forever', percentDiscount: 50 },
    FREE: { id: 'FREE', percentDiscount: 100 },
    '20_percent_1_year': { id: '20_percent_1_year', percentDiscount: 20 },
    '25_percent_1_year': { id: '25_percent_1_year', percentDiscount: 25 },
    '50_percent_12months': { id: '50_percent_12months', percentDiscount: 50 },
    '35_percent_off_6_months': {
        id: '35_percent_off_6_months',
        percentDiscount: 35,
    },
    '5_percent_discount_once': {
        id: '5_percent_discount_once',
        percentDiscount: 5,
    },
    '10_percent_discount_once': {
        id: '10_percent_discount_once',
        percentDiscount: 10,
    },
    '20_percent_discount_once': {
        id: '20_percent_discount_once',
        percentDiscount: 20,
    },
    '25_percent_discount_once': {
        id: '25_percent_discount_once',
        percentDiscount: 25,
    },
    '35_percent_discount_once': {
        id: '35_percent_discount_once',
        percentDiscount: 35,
    },
    '50_percent_discount_once': {
        id: '50_percent_discount_once',
        percentDiscount: 50,
    },
}

const taxLookup = {
    au: { name: 'GST', taxPercent: 10 },
}
