import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import SessionStore from '../State/SessionStore'
import LoadingSpinner from '../Components/LoadingSpinner'
import LoginPage from './LoginPage'
import TimesheetAppPage from './TimesheetApp/TimesheetAppPage'
import { useClerk } from '@clerk/clerk-react'
import { jwtDecode } from 'jwt-decode'
import { usingNewLogin } from '../State/Permissions/HasPermissions'

export let clerk

async function getActiveOrganization(clerkClient) {
    const memberships = await clerkClient.user.getOrganizationMemberships()
    const firstOrg = memberships.data[0]?.organization
    return firstOrg
}

async function getOrganizationFromInvites(clerkClient) {
    const invites = await clerkClient.user.getOrganizationInvitations()
    await Promise.all(invites.data.map((invitation) => invitation.accept()))
    const firstOrg = invites.data[0]?.organization
    return firstOrg
}

async function getOrganization(clerkClient) {
    const [activeOrg, inviteOrg] = await Promise.all([
        getActiveOrganization(clerkClient),
        getOrganizationFromInvites(clerkClient),
    ])
    const org = activeOrg || inviteOrg
    clerkClient.setActive({ organization: org?.id })
    return org
}

export default observer(() => {
    const clerkClient = useClerk()
    useEffect(() => {
        const updateContext = async () => {
            if (!clerkClient.loaded || !usingNewLogin()) return
            try {
                const user = clerkClient.user
                let org = clerkClient.organization

                if (user && !org) {
                    org = await getOrganization(clerkClient)
                }
                if (user) {
                    const encodedToken = await clerkClient?.session?.getToken?.(
                        {
                            template: 'coincraft',
                        }
                    )
                    if (encodedToken) {
                        const token = jwtDecode(encodedToken)
                        await SessionStore.login({ token })
                    }
                } else {
                    await SessionStore.logout()
                }
            } catch (error) {
                console.error('Error updating context:', error)
            }
        }

        updateContext()

        // Set the exported clerk instance
        clerk = clerkClient || clerk
    }, [clerkClient.loaded])
    if (!clerkClient.loaded && !SessionStore.authenticated) {
        return <LoadingSpinner />
    } else if (
        (usingNewLogin() && clerkClient.loaded && !clerkClient.user) ||
        (!usingNewLogin() &&
            SessionStore.initialized &&
            !SessionStore.authenticated)
    ) {
        return <LoginPage />
    } else {
        return <TimesheetAppPage />
    }
})
