import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import SessionStore from '../State/SessionStore'
import { usingNewLogin } from '../State/Permissions/HasPermissions'
import { SignIn } from '@clerk/clerk-react'

export default observer(() => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                ...(usingNewLogin()
                    ? {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                      }
                    : {}),
            }}
        >
            {usingNewLogin() ? (
                <SignIn
                    signUpUrl={`${process.env.REACT_APP_WEB_CLIENT_URL}/register`}
                    forceRedirectUrl={'/'}
                />
            ) : (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={
                                new URL(
                                    '/public/coincraft_logo_130.png',
                                    import.meta.url
                                )
                            }
                            alt="Coincraft Logo"
                        />
                    </div>
                    <div style={{ textAlign: 'center' }} className="login">
                        <form>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <button
                                className="sign-in-button project-btn"
                                onClick={(e) => {
                                    e.preventDefault()
                                    SessionStore.login({ email, password })
                                }}
                            >
                                Sign In
                            </button>
                        </form>
                    </div>
                </>
            )}
        </div>
    )
})
