import SessionStore from '../State/SessionStore'

export default (a, b) => {
    // Check if either phase is a root phase
    if (a?.isRootPhase && !b?.isRootPhase) {
        return 1 // a is root, so it should be last
    } else if (!a?.isRootPhase && b?.isRootPhase) {
        return -1 // b is root, so a should come first
    }

    if (SessionStore.settings.sortPhasesBy === 'startDate') {
        // Check if both items have a startDate
        if (a.startDate && b.startDate) {
            // If both have a startDate, first sort by startDate
            if (a.startDate !== b.startDate) {
                return new Date(a.startDate) - new Date(b.startDate)
            }
            // If startDate is the same, sort by title
            return a.title?.localeCompare?.(b.title)
        } else if (a.startDate) {
            // If only a has a startDate, it should come first
            return -1
        } else if (b.startDate) {
            // If only b has a startDate, it should come first
            return 1
        }
    }
    // If neither has a startDate, sort by title
    return a.title?.toLowerCase?.()?.localeCompare?.(b.title?.toLowerCase?.())
}
